/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH, faQuestionCircle, faPencilAlt, faTrashAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet";
import { useHashConnect } from '../contexts/HashConnectContext';
import { interests } from '../assets/interests';
import logo from '../assets/logo-transparent.png';


const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

const ThreadIndex = () => {
  const { accountId } = useHashConnect();
  const { interestId } = useParams();
  const [hashpackError, setHashpackError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredThreads, setFilteredThreads] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filters, setFilters] = useState(() => {
    const savedFilters = localStorage.getItem('filters');
    return savedFilters ? JSON.parse(savedFilters) : [];
  });
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  const filterInput = useRef(null);
  const editingInput = useRef(null);

  const navigate = useNavigate();

  const goToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  const createNewThread = () => {
    if (!accountId) {
      setHashpackError('Connect your hashpack wallet to create a new thread');
      return;
    }
    navigate(`/${interestId}/new-thread`);
  };

  const refreshPage = () => {
    fetchThreads();
  };

  const fetchThreads = async (interestId) => {
    const response = await fetch(`/get-all-threads?interest=${interestId}`);
    if (!response.ok) {
      throw new Error('Error fetching threads');
    }
    return response.json();
  };

  const getInterestName = (interestId) => {
    const interest = interests.find((i) => i.path === `/${interestId}`);
    return interest ? interest.name : '';
  };

  const interestName = getInterestName(interestId);

  const { data: threads, error, refetch } = useQuery(['threads', interestId], () => fetchThreads(interestId), {
    staleTime: 1000 * 60 * 5, // The fetched data will be considered fresh for 5 minutes
    cacheTime: 1000 * 60 * 30, // The fetched data will stay in cache for 30 minutes
    retry: 1, // If the query fails, it will retry once
    refetchOnWindowFocus: false, // The query won't refetch when the window gets focus
    onError: (error) => console.error('Error fetching threads:', error),
  });

  useEffect(() => {
    refetch();
  }, [interestId, accountId, refetch]);

  useEffect(() => {
    const filterThreads = () => {
      if (!threads) {
        setFilteredThreads([]);
        return;
      }

      const filtered = threads.filter((thread) => {
        const combinedText = `${thread.subject} ${thread.excerpt}`.toLowerCase();
        return (
          combinedText.includes(searchTerm.toLowerCase()) &&
          filters.every((filter) => applyFilters(thread, filter))
        );
      });
      setFilteredThreads(filtered);
    };

    filterThreads();
}, [threads, searchTerm, filters]);


  useEffect(() => {
    localStorage.setItem('filters', JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    let timer;
    if (hashpackError) {
      timer = setTimeout(() => {
        setHashpackError(null);
      }, 3000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [hashpackError]);

  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  const addFilter = () => {
    const newFilter = filterInput.current.value;
    if (newFilter) {
      setFilters([...filters, newFilter]);
      filterInput.current.value = '';
    }
  };

  const handleAddFilterSubmit = (e) => {
    e.preventDefault();
    addFilter();
  };

  const deleteFilter = (index) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  const startEditing = (index) => {
    setEditingIndex(index);
  };

  const updateFilter = () => {
    if (editingInput.current.value) {
      setFilters(
        filters.map((filter, index) =>
          index === editingIndex ? editingInput.current.value : filter
        )
      );
      setEditingIndex(null);
    }
  };

  const saveAndExitFilterMenu = () => {
    if (editingIndex !== null) {
      updateFilter();
    }
    toggleFilterModal();
  };

  const handleEditingInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      updateFilter();
    }
  };

  const applyFilters = (thread, filter) => {
    const text = `${thread.subject} ${thread.excerpt}`.toLowerCase();
    const userId = thread.userId ? thread.userId.toLowerCase() : '';

    const andGroups = filter.split('&').map((t) => t.trim());

    const checkTerm = (term, content) => {
      if (term.includes('*')) {
        const regex = new RegExp('^' + term.split('*').map((t) => t && '\\b' + t + '\\b').join('.*') + '$', 'i');
        return regex.test(content);
      } else if (term.startsWith('/') && term.endsWith('/')) {
        const regex = new RegExp(term.slice(1, -1), 'i');
        return regex.test(content);
      } else if (term.startsWith('-')) {
        term = term.slice(1);
        const isNegativeUserId = term.startsWith('##');
        if (isNegativeUserId) {
          term = term.slice(2);
          return !userId.includes(term.toLowerCase());
        } else {
          const regex = new RegExp('\\b' + term + '\\b', 'i');
          return !regex.test(content);
        }
      } else if (term.startsWith('##')) {
        return userId.includes(term.slice(2).toLowerCase());
      } else {
        const regex = new RegExp('\\b' + term + '\\b', 'i');
        return regex.test(content);
      }
    };

    const andMatch = andGroups.every((group) => {
      const orTerms = group.split('|').map((t) => t.trim());
      const groupMatches = orTerms.some((term) => checkTerm(term, text));
      const hasNegativeTerm = orTerms.some((term) => term.startsWith('-'));
      return hasNegativeTerm ? groupMatches && orTerms.every((term) => checkTerm(term, text)) : groupMatches;
    });

    return andMatch;
  };

  const toggleHelpModal = () => {
    setShowHelpModal(!showHelpModal);
  };

  return (
    <div>
      <Helmet>
        <title>{`/${interestId}/ - ${interestName} - 4orum.io`}</title>
        <meta name="description" content={`Explore diverse discussions at the /${interestId}/ 
  Thread Index on 4orum.io, and get paid for your content while your at it- Free Speech Set Free`} />
        <meta property="og:title" content={`/${interestId}/ - ${interestName} - 4orum.io`} />
        <meta property="og:description" content={`Explore diverse discussions at the /${interestId}/ 
  Thread Index on 4orum.io, and get paid for your content while your at it- Free Speech Set Free`} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/images/${logo}`} />
      </Helmet>
      <h2 className="interest-title">/{interestId}/ - {interestName}</h2>
      <div>
        <div className="main-content">
          <div className="ThreadIndexTopButtons">
            <button onClick={goToBottom}>
              Bottom
            </button>
            <button onClick={createNewThread}>
              New Thread
            </button>
            <button onClick={refreshPage}>
              Refresh
            </button>
          </div>
          <div>
            {hashpackError && <div className="error centered-error">{hashpackError}</div>}
          </div>
          <div className="search-container">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search threads..."
              className="search-input"
            />
            <button className="filter-icon" onClick={toggleFilterModal}>
              <FontAwesomeIcon icon={faSlidersH} />
            </button>
          </div>
          {showFilterModal && (
            <div className="filter-modal">
              <h3>
                Filter Rules
                <FontAwesomeIcon icon={faQuestionCircle} onClick={toggleHelpModal} />
              </h3>
              <div className="filter-list">
                {filters.map((filter, index) => (
                  <div key={index} className="filter-item">
                    {editingIndex === index ? (
                      <input
                        type="text"
                        ref={editingInput}
                        defaultValue={filter}
                        className="editing-input"
                        onKeyDown={handleEditingInputKeyDown}
                      />
                    ) : (
                      <span>{filter}</span>
                    )}
                    {editingIndex === index ? (
                      <button className="edit-icon" onClick={updateFilter}>
                        <FontAwesomeIcon icon={faSave} />
                      </button>
                    ) : (
                      <button className="edit-icon" onClick={() => startEditing(index)}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    )}
                    <button className="delete-icon" onClick={() => deleteFilter(index)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                ))}
              </div>
              <div className="add-filter">
                <form onSubmit={handleAddFilterSubmit} className="add-filter-form">
                  <input type="text" ref={filterInput} placeholder="Add filter..." className="add-filter-input" />
                  <button type="submit" className="add-filter-btn">Add Filter</button>
                  <button onClick={saveAndExitFilterMenu} className="save-and-exit-icon">
                    <FontAwesomeIcon icon={faSave} />
                  </button>
                </form>
              </div>
            </div>
          )}
          {showHelpModal && (
            <div className="help-modal">
              <h3>Filter Help</h3>
              <p>
                Use filters to include or exclude threads based on words or phrases. Add a '-' in front of a
                term to exclude threads containing that term. Use '&' for AND conditions and '|' for OR conditions.
              </p>
              <p>Examples:</p>
              <ul>
                <li>cats & -dogs: show threads with 'cats' and hide threads with 'dogs'</li>
                <li>cat | dog: show threads with either 'cat' or 'dog'</li>
                <li>##0.0.3427845: show threads created by 0.0.3427845</li>
                <li>-##0.0.3427845: hide threads created by 0.0.3427845</li>
                <li>funny cat: show threads with the exact phrase 'funny cat'</li>
              </ul>
              <button onClick={toggleHelpModal} className="close-help">
                Close Help
              </button>
            </div>
          )}
          <div className="ThreadIndex">
            {filteredThreads.map((thread) => (
              <Link key={thread.threadId} to={`/${thread.interestId}/thread/${thread.threadId}`}>
                <div className="ThreadCard">
                  {thread.image && (
                    <div className="ThreadIndexThumbnail">
                      <img
                        src={`https://cloudflare-ipfs.com/ipfs/${thread.image}`}
                        alt="Thread thumbnail"
                        className="ImageContainer"
                      />
                    </div>
                  )}
                  <div className="ThreadCardInfo">
                    <p className="ReplyCount">R: {thread.replyCount}</p>
                    <h3>{thread.subject}</h3>
                    <p className="ThreadCardComment">{thread.excerpt}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        {showFilterModal && (
          <div className="modal-backdrop" onClick={toggleFilterModal}></div>
        )}
        {showHelpModal && (
          <div className="modal-backdrop" onClick={toggleHelpModal}></div>
        )}
        <div className="top-button-container">
          <button className="text-button top-button" onClick={() => window.scrollTo(0, 0)}>
            Top
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThreadIndex;